import React from 'react';
import './App.css';

const App = () => {
  return (
    <div className="container">
      <header className="header">
        <nav>
          <ul className="nav-list">
            <li><a href="#home">Home</a></li>
            <li><a href="#about">About</a></li>
            <li><a href="#contact">Contact</a></li>
          </ul>
        </nav>
      </header>

      <section id="home" className="home">
        <h1>Sustainable Renewable Energy Organization</h1>
        <p>Making $5/kg eH<sub>2</sub> a reality</p>
        <button className="cta-button">Learn More</button>
      </section>

      <section id="about" className="about">
        <h2>About Us</h2>
        <p>We believe $5/kg eH<sub>2</sub> can move the transportation of goods away from fossil fuels for good, whilst creating an ecosystem in which eH<sub>2</sub> could be readily available for use in industries as well.</p>
        <br></br>
        <p>We're stealthily continuing our lifelong journey of combating climate change. </p>
        <br></br>
        <p>To purchase tube trailers of SREO's eH<sub>2</sub>, which is produced through electrolysis from electricity produced/sourced in an eco-friendly manner, please reach out directly or fill out the below contact form.</p>
      </section>

      <section id="contact" className="contact">
        <h2>Contact Us</h2>
        <form>
          <input type="text" placeholder="Name" />
          <input type="email" placeholder="Email" />
          <textarea placeholder="Message"></textarea>
          <button type="submit">Send Message</button>
        </form>
        <br></br>
        <br></br>
        <div id="contactDetails" className="contactDetails">
          <h3>Sustainable Renewable Energy Organization Inc.</h3>
          <p>Mission, San Francisco</p>
          <p>California, 94103</p>
          <p>(415) 740-0273</p>
          <p>stephen.radmard@gmail.com</p>
          <p>sradmard@sreo.ca</p>
        </div>
      </section>

      <footer className="footer">
        <p>&copy; 2023 Sustainable Renewable Energy Organization Inc. All rights reserved.</p>
        <p>SREO<sup>TM</sup> and eH<sub>2</sub><sup>TM</sup> are trademarks of Stephen Radmard</p>
        <ul className="social-list">
          <li><a href="https://www.linkedin.com/company/30643118">LinkedIn</a></li>
        </ul>
      </footer>
    </div>
  );
};

export default App;
